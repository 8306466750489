<template>
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content mapbox-content" style="position: absolute; top: 0; bottom: 0; width: 100%; max-height: 100%; max-width: 480px;">
            <button class="modal-close is-large" aria-label="close" @click.prevent="$emit('close-modal')"></button>
            <div id="mapbox" style="width: 100%; height: 100%;"></div>
            <div id="map-bottom">
                <p class="map-info-address">{{selectedText}}</p>
                <button class="button is-primary" @click.prevent="$emit('submit-modal', selectedCoordinate, selectedText)">Terapkan</button>
            </div>
        </div>
        <!-- <div style="position: absolute; top: 0; bottom: 0; width: 100%; max-height: 100%; max-width: 480px;">
            <button class="modal-close is-large" aria-label="close"></button>
        </div> -->
    </div>
</template>
<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'


export default {
    data () {
        return {
            mapBox: null,
            selectedCoordinate: [98.677501, 3.590538],
            selectedText: 'Saat ini hanya tersedia untuk Medan sekitarnya',
        }
    },
    mounted () {
        this.initMapBox()
    },
    unmounted () {
        this.removeMapBox()
    },
    methods: {
        initMapBox () {
            if (this.mapBox === null) {
                this.$nextTick(() => {
                    mapboxgl.accessToken = 'pk.eyJ1IjoiZ2Vkb29yIiwiYSI6ImNsMTJkMWdmdjA5dW0zZHAzYXdlcGtnY2EifQ.GPz7GVgHwfboHt00yF1JXQ';
                    this.mapBox = new mapboxgl.Map({
                        container: 'mapbox',
                        center: [98.677501, 3.590538],
                        zoom: 13,
                        style: 'mapbox://styles/mapbox/streets-v11'
                    })

                    // Add geolocate control to the map.
                    const userGeoLocate = new mapboxgl.GeolocateControl({
                        positionOptions: {
                            enableHighAccuracy: true
                        },
                        // When active the map will receive updates to the device's location as it changes.
                        trackUserLocation: true,
                        // Draw an arrow next to the location dot to indicate which direction the device is heading.
                        showUserHeading: false,
                        showUserLocation: false
                    })
                    userGeoLocate.on('geolocate', (e) => {
                        this.selectedCoordinate = [
                            e.coords.longitude,
                            e.coords.latitude
                        ]
                        marker.setLngLat(this.selectedCoordinate)
                        this.reverseGeoCode (e.coords.latitude, e.coords.longitude)
                    })
                    this.mapBox.addControl(
                        userGeoLocate
                    );

                    const marker = new mapboxgl.Marker({ draggable: true }).setLngLat([98.677501, 3.590538]).addTo(this.mapBox)
                    marker.on('dragend', () => {
                        const lngLat = marker.getLngLat()
                        this.selectedCoordinate = [
                            lngLat.lng,
                            lngLat.lat
                        ]
                        this.reverseGeoCode (lngLat.lat, lngLat.lng)
                        // fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/' + lngLat.lng + ',' + lngLat.lat + '.json?access_token=' + mapboxgl.accessToken + '&limit=1').then(response => response.json()).then(data => {
                        //     this.selectedText = data.features[0].place_name
                        // })
                    })

                    const geocoder = new MapboxGeocoder({
                        accessToken: mapboxgl.accessToken,
                        mapboxgl: mapboxgl,
                        placeholder: 'Cari nama jalan, tempat atau kode pos',
                        marker: false,
                        countries: 'ID'
                        // setBbox: [
                        //     98.5924674,
                        //     3.4881173,
                        //     98.7465201,
                        //     3.8019525
                        // ]
                    })
                    this.mapBox.addControl(geocoder)
                    geocoder.on('result', (e) => {
                        this.selectedCoordinate = e.result.center
                        this.reverseGeoCode (e.result.center[1], e.result.center[0])
                        marker.setLngLat(this.selectedCoordinate)
                        // this.selectedText = e.result.place_name
                        // marker.setLngLat(e.result.center)
                    })

                })
            }
        },
        reverseGeoCode (lat, lng) {
            fetch('https://nominatim.openstreetmap.org/reverse?lat=' + lat + '&lon=' + lng)
            .then(response => response.text())
            .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
            .then(data => {
                this.selectedText = data.getElementsByTagName('reversegeocode')[0].getElementsByTagName('result')[0].firstChild.nodeValue
            })
        },
        removeMapBox () {
            if (this.mapBox !== null) {
                this.mapBox.remove()
            }
        }
    }
}
</script>
