<template>
    <div id="main-content" class="mobile-container" v-if="selectedService !== null" >
        <form>
            <div v-if="step === 1">
                <p class="mb-1 is-size-5 has-text-centered has-text-weight-semibold">{{selectedService.title}}</p>
                <h3 class="has-text-weight-bold mb-1">Masalah</h3>
                <div class="control control-white" v-for="(masalah, key) in selectedService.masalah" :key="key">
                    <label class="radio is-capitalized">
                        <input type="radio" :value="masalah" required v-model="form.masalah" name="masalah">
                        {{masalah}}
                    </label>
                </div>
                <div class="control control-white">
                    <label class="radio">
                        <input type="radio" value="masalah lain" required v-model="form.masalah" name="masalah">
                        Masalah Lain
                    </label>
                </div>
                <input v-if="form.masalah === 'masalah lain'" required class="input input-white" type="text" v-model="form.masalah_lain" placeholder="Jelaskan masalah anda">
                <template v-if="selectedService.rincian.length > 0">
                    <h3 class="has-text-weight-bold mb-1 mt-2">Rincian</h3>
                    <label class="checkbox checkbox-white is-capitalized" v-for="(rincian, key) in selectedService.rincian" :key="key">
                        <input type="checkbox" v-model="form.penjelasan_masalah" :value="rincian"> {{rincian}}
                    </label>
                </template>
                <h3 class="has-text-weight-bold mb-1 mt-2">Foto</h3>
                <div class="mt-0">
                    <div class="file file-white is-boxed">
                        <label class="file-label">
                            <input class="file-input" multiple type="file" name="file_foto" @change="previewFiles" accept="image/*">
                            <span class="file-cta">
                                <span class="file-icon">
                                    <img src="/img/upload-icon.png" alt="">
                                </span>
                                <span class="file-label">
                                    Pilih foto, maksimal 3 foto
                                    <!-- <br>Ukuran file maksimal: 1MB -->
                                </span>
                            </span>
                        </label>
                    </div>
                    <p v-if="processingImage > 0" style="text-align: center;">Foto sedang di proses...</p>
                    <div id="file-preview" v-if="form.file_foto.length > 0">
                        <figure v-for="(file, key) in form.file_foto" :key="key" class="image is-64x64">
                            <span>
                                <img :src="generateImagePreview(file)">
                            </span>
                        </figure>
                    </div>
                </div>
                <h3 class="has-text-weight-bold mb-1 mt-2">Detail Tambahan</h3>
                <textarea class="textarea textarea-white" v-model="form.detail_tambahan" placeholder="Jelaskan disini"></textarea>
                
                <div class="buttons submit-form-button mt-3">
                    <button class="button is-primary" type="button" @click.prevent="validateStep" :disabled="processingImage > 0">Selanjutnya</button>
                </div>
            </div>
            <div v-if="step === 2">
                <h3 class="has-text-weight-bold mb-0">Tanggal & Jam Perjanjian</h3>
                <p class="mb-1 is-size-7">Minimal 3 jam sebelum perjanjian</p>
                <div class="columns is-mobile mb-1">
                    <div class="column date-picker">
                        <input required class="input input-white" type="text" ref="tanggal_booking" placeholder="Tanggal">
                    </div>
                    <div class="column time-picker">
                        <input required class="input input-white" type="text" ref="waktu_booking" placeholder="Waktu">
                    </div>
                </div>
                <h3 class="has-text-weight-bold mb-1">Alamat lengkap</h3>
                <button class="button is-white mb-2 is-small- selected-map-btn is-fullwidth" type="button" @click.prevent="showMap = true"><img src="/img/map-icon-dark.png" style="width: 22px;" class="mr-2" alt=""> {{form.teks_koordinat === null || form.teks_koordinat === '' ? 'Buka Peta' : form.teks_koordinat}}</button>
                <textarea class="textarea textarea-white mb-1" required v-model="form.detail_alamat" placeholder="Nomor, warna cat, lantai atau nama gedung"></textarea>


                <h3 class="has-text-weight-bold mt-2">Nama</h3>
                <input required class="input input-white" type="text" v-model="form.nama" placeholder="Nama">


                <h3 class="has-text-weight-bold mt-2">No. WA</h3>
                <div class="field input-field-white">
                    <p class="control has-icons-left has-icons-right">
                        <input required class="input input-white" type="tel" v-model="form.no_wa" placeholder="Nama">
                        <span class="icon is-small is-left has-text-black">+62</span>
                    </p>
                </div>

                <div class="mt-3">
                    <div class="columns is-mobile mb-1">
                        <div class="column">
                            <div class="buttons submit-form-button">
                                <button class="button is-dark" type="button" @click.prevent="step -= 1">Kembali</button>
                            </div>
                        </div>
                        <div class="column">
                            <div class="buttons submit-form-button">
                                <button class="button is-primary" type="button" @click.prevent="validateStep">Selanjutnya</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="step === 3">
                <h3 class="is-size-4 mb-2">Silahkan cek data anda</h3>
                <h3 class="has-text-weight-bold mb-1">Layanan Yang Dipilih</h3>
                <p>{{selectedService.title}}</p>
                <h3 class="has-text-weight-bold mt-1 mb-1">Masalah</h3>
                <p class="is-capitalized">{{form.masalah === 'masalah lain' ? form.masalah_lain : form.masalah}}</p>

                <h3 class="has-text-weight-bold mt-1 mb-1">Rincian</h3>
                <p class="is-capitalized">{{form.penjelasan_masalah.length ? form.penjelasan_masalah.join(' ,') : '-'}}</p>


                <h3 v-if="form.file_foto.length > 0" class="has-text-weight-bold mt-1 mb-1">Foto</h3>
                <div id="file-preview" v-if="form.file_foto.length > 0">
                    <figure v-for="(file, key) in form.file_foto" :key="key" class="image is-64x64">
                        <span>
                            <img :src="generateImagePreview(file)">
                        </span>
                    </figure>
                </div>

                <h3 class="has-text-weight-bold mt-1 mb-1">Detail</h3>
                <p>{{form.detail_tambahan === null || form.detail_tambahan === '' ? '-' : form.detail_tambahan}}</p>

                <h3 class="has-text-weight-bold mt-1 mb-1">Tanggal & Jam Perjanjian</h3>
                <p>{{formatDate(form.tanggal_booking)}} {{formatDate(form.waktu_booking, 'HH:mm')}}</p>

                <h3 class="has-text-weight-bold mt-1 mb-1">Alamat lengkap</h3>
                <p class="mb-1">{{form.teks_koordinat}}</p>
                <p>{{form.detail_alamat}}</p>

                <h3 class="has-text-weight-bold mt-1 mb-1">Nama</h3>
                <p class="mb-1">{{form.nama}}</p>

                <h3 class="has-text-weight-bold mt-1 mb-1">No. WA</h3>
                <p class="mb-1">+62 {{form.no_wa}}</p>
                <h3 class="box has-background-warning has-text-white has-text-weight-semibold is-capitalized p-2 mt-2 ">Semua transaksi pembayaran hanya  melalui transfer ke BCA no.rek 8205136360</h3>
                <div class="mt-3">
                    <div class="columns is-mobile mb-1">
                        <div class="column">
                            <div class="buttons submit-form-button">
                                <button class="button is-dark" type="button" @click.prevent="step -= 1">Kembali</button>
                            </div>
                        </div>
                        <div class="column">
                            <div class="buttons submit-form-button">
                                <button class="button is-primary" type="button" @click.prevent="submit" :class="{ 'is-loading': isLoading }">Kirim</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <modal-map-box v-if="showMap" @close-modal="showMap = false" @submit-modal="onSelectedMap"/>
    </div>
</template>

<script>
import Compressor from 'compressorjs'
import { serializeErrorMessage } from '@/_helpers'
import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.min'
import ModalMapBox from '@/components/ModalMapBox.vue'
import { format } from 'date-fns'
import servicesData from "@/json/services.json"

const today = new Date()
today.setHours(today.getHours() + 3)

export default {
    components: {
        ModalMapBox
    },
    data () {
        return {
            step: 1,
            isLoading: false,
            showMap: false,
            form: {
                layanan_dipilih: null,
                masalah: null,
                masalah_lain: null,
                detail_alamat: null,
                // teks_koordinat: 'Analisa, Jl. A. Yani No.35-49, Medan, North Sumatra 20111, Indonesia',
                teks_koordinat: null,
                titik_koordinat: null,
                detail_tambahan: null,
                nama: null,
                no_wa: null,
                tanggal_booking: today,
                waktu_booking: today,
                penjelasan_masalah: [],
                file_foto: []
            },
            selectedService: null,
            processingImage: 0
        }
    },
    watch: {
        step (newValue) {
            if (newValue === 2) {
                this.initDateTimePicker()
            }
        }
    },
    mounted() {
        if (typeof servicesData[this.$route.params.serviceName] === 'undefined') {
            this.$router.push('/')
        } else {
            this.selectedService = servicesData[this.$route.params.serviceName]
            this.form.layanan_dipilih = this.$route.params.serviceName
        }
        if (localStorage.getItem('myaccount') !== null && localStorage.getItem('myaccount') !== '') {
            const myaccount = JSON.parse(localStorage.getItem('myaccount'))
            this.form.nama = myaccount.name
            this.form.no_wa = myaccount.phone_number
            this.form.titik_koordinat = myaccount.address.titik_koordinat
            this.form.teks_koordinat = myaccount.address.teks_koordinat
            this.form.detail_alamat = myaccount.address.detail_alamat
        }
        // if (this.step === 2) {
        //     this.initDateTimePicker()
        // }
    },
    methods: {
        validateStep () {
            if (this.step === 1) {
               if (this.form.masalah === null || this.form.masalah === '') {
                   this.$notify({ type: 'error', text: 'Masalah wajib diisi' })
                   return false
               } else if (this.form.masalah === 'masalah lain' && (this.form.masalah_lain === null || this.form.masalah_lain === '')) {
                   this.$notify({ type: 'error', text: 'Penjelasan masalah lain wajib diisi'})
                   return false
               }
            } else if (this.step === 2) {
               if (this.form.tanggal_booking === null || this.form.tanggal_booking === '') {
                   this.$notify({ type: 'error', text: 'Tanggal wajib diisi'})
                   return false
               } else if (this.form.waktu_booking === null || this.form.waktu_booking === '') {
                   this.$notify({ type: 'error', text: 'Waktu wajib diisi'})
                   return false
               } else if (this.form.titik_koordinat === null || this.form.titik_koordinat === '') {
                   this.$notify({ type: 'error', text: 'Titik alamat wajib diisi'})
                   return false
               } else if (this.form.nama === null || this.form.nama === '') {
                   this.$notify({ type: 'error', text: 'Kolom nama wajib diisi'})
                   return false
               } else if (this.form.no_wa === null || this.form.no_wa === '') {
                   this.$notify({ type: 'error', text: 'Kolom no. wa wajib diisi'})
                   return false
               }
            }
            window.scroll({
                top: 100,
                left: 100,
                behavior: 'smooth'
            });
            this.step++
        },
        submit () {
            if (!this.isLoading) {
                let a = {
                    'no_wa': this.form.no_wa,
                    'nama': this.form.nama,
                    'layanan_dipilih': this.form.layanan_dipilih,
                    'masalah': this.form.masalah === 'masalah lain' ? this.form.masalah_lain : this.form.masalah,
                    'penjelasan_masalah': this.form.penjelasan_masalah.length > 0 ? this.form.penjelasan_masalah : null,
                    // 'file_foto': this.form.file_foto.length > 0 ? this.form.file_foto : null,
                    'detail_tambahan': this.form.detail_tambahan,
                    'tanggal_booking': format(this.form.tanggal_booking, 'yyyy-MM-dd'),
                    'waktu_booking': format(this.form.waktu_booking, 'HH:mm' + ':00'),
                    'titik_koordinat': this.form.titik_koordinat,
                    'teks_koordinat': this.form.teks_koordinat,
                    'detail_alamat': this.form.detail_alamat,
                    'fcm_token': window.fcm_token
                }
                const data = new FormData()
                Object.keys(a).forEach(key => {
                    if (a[key] !== null && a[key] !== '') {
                        data.append(key, a[key])
                    }
                })
                
                if (this.form.file_foto.length) {
                    for(let i = 0; i < this.form.file_foto.length; i++) {
                        data.append('file_foto[' + i + ']', this.form.file_foto[i], this.form.file_foto[i].name)
                    }
                }
                this.isLoading = true
                fetch(process.env.VUE_APP_BASE_URL + 'api/submit-order', {
                    method: 'post',
                    headers: {
                        // 'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: data,
                }).then(async response => {
                    this.isLoading = false
                    const isJson = response.headers.get('content-type')?.includes('application/json')
                    const data = isJson ? await response.json() : null

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = data || response.status
                        return Promise.reject(error)
                    }
                    localStorage.setItem('myaccount', JSON.stringify({
                        'id': data.user.id,
                        'name': data.user.name,
                        'phone_number': data.user.phone_number,
                        'address': {
                            'titik_koordinat': data.voucher.titik_koordinat,
                            'teks_koordinat': data.voucher.teks_koordinat,
                            'detail_alamat': data.voucher.detail_alamat
                        }
                    }))
                    // this.$router.push('/orders/' + data.voucher.kode_voucher)
                    this.$router.push('/order-list')
                    this.$notify({ type: 'success', text: 'Permintaan survei anda berhasil disimpan.'})
                }).catch(error => {
                    this.isLoading = false
                    this.$notify({ type: 'error', text: serializeErrorMessage(error)})
                })
            }
        },
        previewFiles (event) {
            if (event.target.files.length > 3) {
                this.$notify({ type: 'error', text: 'Maksimal 3 foto'})
            } else if (event.target.files.length > 0) {
                this.form.file_foto = []
                const th = this
                for(let i = 0; i < event.target.files.length; i++) {
                    th.processingImage++
                    new Compressor(event.target.files[i], {
                        quality: 0.6,
                        // The compression process is asynchronous,
                        // which means you have to access the `result` in the `success` hook function.
                        success(result) {
                            th.processingImage--
                            th.form.file_foto.push(result)
                        },
                        error(err) {
                            th.processingImage--
                            console.log(err.message)
                        },
                    })
                }
                // this.form.file_foto = event.target.files
            }
        },
        initDateTimePicker () {
            this.$nextTick(() => {
                const tanggal_booking_picker = bulmaCalendar.attach(this.$refs.tanggal_booking, {
                    startDate: this.form.tanggal_booking,
                    type: 'date',
                    dateFormat: 'dd/MM/yyyy',
                    lang: 'id',
                    showClearButton: false,
                    cancelLabel: 'Tutup',
                    todayLabel: 'Hari ini'
                })[0]
                tanggal_booking_picker.on('select', (e) => {
                    this.form.tanggal_booking = e.data.startDate
                })

                const waktu_booking_picker = bulmaCalendar.attach(this.$refs.waktu_booking, {
                    startTime: this.form.waktu_booking,
                    type: 'time',
                    // dateFormat: 'dd/MM/yyyy',
                    lang: 'id',
                    showClearButton: false,
                    cancelLabel: 'Tutup',
                    todayLabel: 'Hari ini',
                    validateLabel: 'Pilih'
                })[0]
                waktu_booking_picker.on('select', (e) => {
                    this.form.waktu_booking = e.data.startTime
                })
            })
        },
        generateImagePreview (file) {
            return window.URL.createObjectURL(file)
        },
        onSelectedMap (a, b) {
            this.showMap = false
            this.form.titik_koordinat = a
            this.form.teks_koordinat = b
        },
        formatDate(val, formatText = 'dd/MM/yyyy') {
            if (val !== null && val !== '') {
                return format(val, formatText)
            }
        }
    }
}
</script>
